* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto Mono', monospace;
}

body{
  position: relative;
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

::-webkit-scrollbar {
  width: 1.2em;
}

::-webkit-scrollbar-track {
  border-radius: 100vw;
  margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: rgb(221, 221, 221);
  border: 0.25em solid rgb(255, 255, 255);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(194, 194, 194);
}

@supports(scrollbar-color: rgb(221, 221, 221) white){
  *{
    scrollbar-color: rgb(221, 221, 221) white;
  }
}