#morph{
    width: 100%;
    height: 100%;
    display: block;
}

.footer-content{
    background-color: rgb(0, 0, 0);
    text-align: center;
    min-height: 50rem;
}

footer{
    color: white;
    background-color: black;
    padding-top: 2rem;
}

.linkedin-link{
    color: white;
}

.footer-content{
    padding-top: 5rem;
}

address{
    text-align: center;
    padding-top: 3rem;
}

.arrowUp{
    max-height: 6rem;
    max-width: 6rem;
    margin: auto;
    padding-top: 3rem;
}