  .divGrid{
    --gap: 3rem;
    --num-cols: 2;
    --row-height: 2;

    box-sizing: border-box;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
    gap: var(--gap);
  }

  .aloneGrid{
    --gap: 3rem;
    --num-cols: 2;
    --row-height: 30rem;

    box-sizing: border-box;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10%, 75%));
    justify-content: center;
    padding-top: 3rem;
  }

.cards {
    padding-top: 20%;
    background: rgb(255, 255, 255);
    position: relative;
    min-height: 1300px;
  }

  .cards_container{
    max-width: 56%;
    margin: auto;
  }
  
  .card {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex: 1;
    cursor: pointer;
  }
  
  .card_link {
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-flow: column;
    width: 100%;
    /*height: 100%;*/
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.017));
    filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.017));
    overflow-y: auto;
    text-decoration: none;
  }

  .opened-card {
    border-radius: 15px;
    height: 90vh;
    width: 80%;
    position: absolute;
    top: 100;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  
  .card .card_img-wrap {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .card .card_img {
    padding-top: 0px;
    width: 100%;
    text-align: center;
  }

  .opened-card .card_img-wrap {
    padding-top: 30px;
    width: 100%;
    text-align: center;
  }
  
  .opened-card .card_img{
    width: 50%;
  }
  
  .card_title, .card_desc {
    padding: 20px 30px 30px;
    background-color: rgb(255, 255, 255);
  }
  
  .card_title-text {
    color: #000000;
    font-size: 18px;
    line-height: 24px;
  }

  .card_desc-text {
    color: black;
    font-size: 1vw;
    /*line-height: 24px;*/
    width: 70%;
    margin: auto;
  }

  .ytVideo{
    border-radius: 15px;
    aspect-ratio: 16 / 9;
	  height: 100%;
	  width: 100%;
  }

  .video-wrap{
    justify-content: center;
    display: flex;
    width: 70%;
    margin: auto;
    padding-top: 60px;
  }

  .link-wrap{
    padding-top: 60px;
    text-align: center;
  }

  .link-wrap .fa-brands{
    padding-left: 3px;
  }

  .link{
    color: black;
    font-weight: bold;
    padding-left: 5px;
  }

  .grid-right, .grid-left, .grid-left-second, .grid-right-second{
    padding-top: 3rem;
  }

  .dim-layer {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: black;
    opacity: 0;
  }

  @media only screen and (max-width: 800px) and (min-width: 714px) {
    .divGrid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 488px));
      gap: 0
    }
  }

  @media only screen and (max-width: 714px) {
    .divGrid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 0
    }
  }

  @media only screen and (max-width: 800px) {
    .aloneGrid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .card_desc-text{
      font-size: 10px;
      width: 90%;
    }

    .opened-card .card_img{
      width: 100%;
    }

    .opened-card .card_img-wrap{
      padding-top: 0;
    }

    .video-wrap{
      width: 100%;
    }

    .card_desc{
      padding-left: 15px;
      padding-right: 15px;
    }
  }